.screen{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.screenTitle{
    font-size: 40px;
    font-weight: bold;
}

.chartContainer{
    margin: auto;
    width: 60%;
    height: 600px;
}

.reqMonitTable > * > * > *{
    border: 1px solid grey;
}

@media screen and (max-width: 700px) {
    .screen{
        width: 100vw;
    }

    .screenTitle{
        font-size: 1.6rem;
    }

    .chartContainer{
        width: 90vw;
    }
}