.navbar{
    background-color: #ff0f64;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn{
    background-color: #ff0f64;
    height: 45px;
    width: 300px;
    text-align: center;
    color: white;
    font-size: 24px;
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 15px;
    padding-top: 0.25%;

}

.btn:hover{
    cursor: pointer;
    
}