.navButton{

    /* Gap */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    font-family: "Poppins", sans-serif;
    font-display: swap;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    backface-visibility: hidden;
    border: 0.3rem solid transparent;
    border-radius: 10px !important;
    border-color: transparent;
    background-color: #fff;
    color: #ff0f64;
    transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    white-space: nowrap;
    height: 35px;
    display: flex;
    width: fit-content;
    align-items: center;    
    justify-content: space-around;
    margin: 10px 0 10px 0;
}

.navButton:hover{
    transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0);
}