.productsTable{
    width: 80%;
    margin: auto;
    overflow-y: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    scrollbar-color: red;
    border-radius: 13px;
    min-height: 300px;
    border-spacing: 0 23px;
}
.editScreenContex{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.4); 
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editBtn{
    width: 40px;
    height: 40px;
    background-color: #ff0f64;
    margin: auto; 
    border-radius: 12px;
    padding: 3px;
    text-align: center
}

.editBtn:hover{
    transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0) !important;
}

th{
    min-width: 60px;
    text-align: center;
    padding: 10px;
}

td{
    max-height: 90px;
}

/* .tableFixHead          { overflow: auto; height: 500px; width: 100%; margin: auto; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; } */

.tableFixHead          { overflow: auto; height: 500px; width: 100%; padding-bottom: 15px}
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }
.tableFixHead tbody th { position: sticky; left: 0; }

table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }

#deleteBtn:hover{
    transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0) !important;
}