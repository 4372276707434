.nfContainer{
    background-color: white;
    height: 60vh;
    width: 80vw;
    display: flex;
    flex-direction: row;
    border-radius: 14px;
}

.img{
    width: 100%;
    height: 150%;
    position: relative;
    top: -25%;
    left: -10%
}

.text{
    font-size: 7vh;
    position: absolute;
    right: 12%;
    text-align: center;
    width: 40%;
    color: #ff0f64;
}

.t404{
    margin-bottom: 30px;
}

@media screen and (max-width: 600px) {
    .nfContainer{
        /* display: inline-flex; */
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    }

    .img{
        position: relative;
        top: auto;
        left: 20%;
        width: 85%;
    }

    .text{
        width: auto;
        position: relative;
        right: auto;
        margin: auto;
    }

    .t404{
        margin-bottom: -40px;
    }
}