.cpContainer{
    /* border: 1px solid white; */
    width: 60vh;
    height: fit-content;
    padding: 15px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
    margin-bottom: -30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 12px;
    background-color: white;
    border: 1px solid rgba(100, 100, 111, 0.2);
}

.cpBar{
    top: 0;
    width: 100%;
    height: 60px;
    background: rgb(255,15,100);
    background: linear-gradient(90deg, rgba(255,15,100,1) 38%, rgba(121,9,81,1) 97%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.section{
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 24px;
    padding: 10px;
    color: #ff0f64;
    background-color: white;
    border-bottom-left-radius: 20px;
    margin-bottom: 10px;
    border-top-left-radius: 20px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

}

.section:hover{
    transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0) !important;
}

.Logo{
    width: 55vh;
    height: auto;
    position: absolute;
    top: -35px;
    bottom: 3px;
}

.cpTitle{
    color: #ff0f64;
    font-size: 6vh;
    white-space: nowrap;
}
.FlexContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.cpInput{
    width: 100%;
    min-height: 4vh;
    background-color: white;
    font-size: large;
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 3px;
    padding-left: 10px;
    color: rgb(114, 99, 99);
    margin-bottom: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin: 10px;
}

.btnShadow{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.cpMessage{
    font-size: 20px;
    margin-top: 45px;
    color: #ff0f64;
}

.accountListContainer{
    width: 70%;
    height: 300px;
    background-color: white;
    border-radius: 14px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 1px solid rgba(100, 100, 111, 0.2);
    overflow: auto;
}

.accountI{
    width: 95%;
    height: 50px;
    background-color: white;
    display: flex;
    flex-direction: row;
    border-radius: 14px;
    -webkit-box-shadow: 7px 4px 7px 1px rgba(0,0,0,0.61); 
    box-shadow: 7px 4px 7px 1px rgba(0,0,0,0.61);
    margin: auto;
    margin-bottom: 15px;
}

.Contractsrow{
    width: 100%;
}

.listHeader{
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: rgb(255,15,100);
    background: linear-gradient(90deg, rgba(255,15,100,1) 38%, rgba(121,9,81,1) 97%);
    border-radius: 14px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 10px;
    width: 100%;
}

.column{
    width: 16.66%;
    text-align: center;  
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
}

.ColumnsTitle{
    color: white;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;

}
.Contractsrow{
    width: 95%;
}

.accountListScroll::-webkit-scrollbar {
    display: none;
}

.accountListScroll{
    height: 100%;
    overflow-y: scroll;
}

.listHeader>p{
    font-weight: bold;
    margin-right: auto;
    margin-left: auto;
    width: fit-content;
    color: white;
}

.colel{
    font-weight: 500;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    padding-right: 5%;
}

.bntdownload{
    background-color: #ff0f64;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

}

.Icon{
    width: 50px;
    height: 50px;
}

.Log{
    padding-left: 5%;
}

.Signed{
    padding-left: 3%;
}

.accountI>p{
    font-weight: 500;
    width: 250px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
}

.cpLayout{
    display: grid;
    grid-template-columns: 60px auto;
    width: 100%;
    height: 100%;
    position: relative;
}

.cpSidebBar{
    height: 100%;
    background-color: #ff0f64;
    padding-left: 10px; 
    display: flex;
    flex-direction: column;
    transition: width 1s;
    -webkit-transition: width 1s;
}

.cpSide:hover{
    width: 300px;
}


@media screen and (max-width: 600px) {
    .cpContainer{
        max-width: 95%;
        margin: auto;
    }

    .cpTitle{
        font-size: 12vw;
    }

    .cpMessage{
        margin-top: 1vh;
    }

    .accountListContainer{
        width: 95%;
        height: auto;
        max-height: 30vh;
        margin: auto;
        margin-bottom: 20px;

    }

    .listHeader>p{
        font-weight: bold;
        margin-right: auto;
        margin-left: auto;
        width: fit-content;
    }
}