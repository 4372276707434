.alertBackground{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 40;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert{
    margin-bottom: 8%;
    height: '40%'
}

.alertText{
    font-size: 1.8rem;
}

.alertBtn{
    width: inherit;
}

@media screen and (max-width: 700px) {

    .alertBtn{
        margin: 5px;
        width: 50%;
        height: 50px;
    }

    .alertText{
        font-size: 1.3rem;
    }

    .alert{
        margin-bottom: auto;
        height: 50%;
    }
}