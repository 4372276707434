.landingContex{
    width: 80%;
    height: 70%;
    -webkit-box-shadow: 7px 4px 7px 1px rgba(0,0,0,0.61); 
    box-shadow: 7px 4px 7px 1px rgba(0,0,0,0.61);
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.landingTitle{
    background-color: #FF0F64;
    width: 100%;
    height: fit-content;
    color: white;
    text-align: center;
    font-size: 60px;
    margin: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

}

.landingGuideSection{
    -webkit-box-shadow: 7px 4px 7px 1px rgba(0,0,0,0.61); 
    box-shadow: 7px 4px 7px 1px rgba(0,0,0,0.61);
    border-radius: 12px;
    min-height: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landingGuideSectionList{
    display: grid;
    grid-template-columns: 49% 49%;
    width: 100%;
    column-gap: 10px;
    margin-top: 10px; 
    overflow-y: auto;
    height: 400px;
}



.landingGuideText{
    width: 90%;
    font-size: 18px;
    font-weight: 500;
}

.pTitleRow{
    font-size: 3vh;
}

table th:first-child{
    border-radius: 13px 0 0 0;
  }
  
  table th:last-child{
    border-radius:0 13px 0 0;
  }

@media screen and (max-width: 700px){
    .landingGuideSectionList{
        display: flex;
        flex-direction: column;
        overflow-y: visible;
        row-gap: 20px;
    }

    .landingGuideSection{
        min-height: fit-content;
    }
}