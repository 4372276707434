.AutoCompleteFibre, .FibreRegions{
    width: 20vw;
}
.dialogmap{
    width: 60vw;
    height: 60vh;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #ff0f64;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.dialogmap:not([open]) {
    display: none;
}
#CloseDialog{
    margin-right: 55vw;
}
.btn{
    justify-content: center;
    display: flex;
    align-items: center;
}
#map{
    border-radius: 28px;
}
