.lContainer{
    /* border: 1px solid white; */
    width: 50vh;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Logo{
    width: 55vh;
    max-width: 100%;
    height: auto;
    position: absolute;
    top: -35px;
    bottom: 3px;
}

.lTitle{
    color: white;
    font-size: 9vh;
    margin-top: 10px;
    margin-bottom: 25px;
}

.lInput{
    width: 80%;
    min-height: 5vh;
    background-color: white;
    font-size: large;
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 3px;
    padding-left: 10px;
    color: rgb(114, 99, 99);
    margin-bottom: 10px;
}


.lInput:hover{
    transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0) !important;
}

.rButton{

    /* Gap */
    margin-top: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    backface-visibility: hidden;
    border: 0.3rem solid transparent;
    border-radius: 3rem;
    border-color: transparent;
    background-color: #fff;
    color: #ff0f64;
    transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    margin-bottom: 30px;

}



.rButton:hover{
    transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0) !important;
}


@media screen and (max-height: 600px) {
    .Logo{
        visibility: hidden;
    }
}

@media screen and (max-width: 600px) {
    .Logo{
        width: 88vw;
    }

    .lContainer{
        width: 85vw;
    }
    
}