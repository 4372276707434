.homeContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 5%;
    height: 100%;
    width: 95%;
    margin: auto;
    align-items: start;
}

.homeCard{
    width: 40%;
    transition: transform .2s;
    cursor: pointer;
    margin-top: 5%;
}

.homeCard:hover{
    transform: scale(1.05);
}

.homeTitle{
    background-color: white;
    text-align: center;
    padding-top: 50px;
}


@media screen and (max-width: 700px){
    .homeContainer{
        flex-direction: column;
        gap: 5%;
        margin-top: 0;
        align-items: center;
        padding-bottom: 15px;
        overflow-y: scroll;
    }

    .homeCard{
        width: 80%;
        margin: 0;
    }

    .homeTitle{
        font-size: large;
        padding-top: 0;
    }
}