.ImportContainer{
    height: 100%;
    width: 45%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 12px;
    background-color: white;
    border: 1px solid rgba(100, 100, 111, 0.2);
    display: flex;
    flex-direction: column;
    margin: auto;
    
}
.css-5ir5xx-MuiLinearProgress-bar1{
    
    background-color: #ff0f64 !important ;
}
.css-1r8wrcl-MuiLinearProgress-bar2{
    
    background-color: #ff0f64 !important ;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    outline-color: #ff0f64 !important;
}
.custom-file-upload:focus{
    outline: none !important;
    border-color: #ff0f64 !important;
}

.row{
    height: 100%;
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
    border: 1px solid rgba(100, 100, 111, 0.2);
    display: flex;
    flex-direction: column;
} 
input[type="file"] {
    display: none;
}

@media screen and (max-width: 600px) {
    .ImportContainer{
        width: 90%;
        height: 100%;
    }
    .inputContainer{
        flex: 0 0 0%;
    }
  }
@media screen and (max-width: 900px) and (min-width: 600px) {
    .ImportContainer{
        width: 70%;
        height: 100%;
    }
  }
    