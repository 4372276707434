.bg{
    /* background: rgb(243,17,98);
    background: linear-gradient(347deg, rgba(243,17,98,0.927608543417367) 8%, rgba(41,44,59,1) 61%); */
    background-color: '#F5F5F5';
    background-size: contain;
    z-index: -7;
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    margin: 0;
    padding: 0;
}

body{
    margin: 0;
    padding: 0;
}